<template>
  <div>
    <c-search-box @enter="getLocList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-select
            :comboItems="useFlagItems"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="useFlag"
            label="사용여부"
            v-model="searchParam.useFlag"
          ></c-select>
        </div>
      </template>
    </c-search-box>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5">
        <c-tree-table
          title="기능위치목록"
          parentProperty="upFuncLocationCd"
          customID="funcLocationCd"
          :columns="grid.columns"
          :data="grid.data"
          :columnSetting="false"
          :isFullScreen="false"
          :usePaging="false"
          :expandAll="true"
          @rowClick="rowClick"
        >
          <template slot="table-button">
            <q-btn-group outline >
              <c-btn label="검색" icon="search" @btnClicked="getLocList" />
            </q-btn-group>
          </template>
        </c-tree-table>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7">
        <q-form ref="editForm">
          <c-card title="기능위치 정보" class="cardClassDetailForm">
            <template slot="card-button">
              <q-btn-group outline >
                <c-btn v-if="editable" label="신규" icon="add" @btnClicked="addData" />
                <c-btn
                  v-if="editable && deleteable"
                  :url="deleteUrl"
                  :isSubmit="true"
                  :param="data"
                  mappingType="DELETE"
                  label="삭제"
                  icon="remove"
                  @beforeAction="deleteData"
                  @btnCallback="deleteCallback" />
                <c-btn
                  v-if="editable && saveable"
                  :url="saveUrl"
                  :isSubmit="isSave"
                  :param="data"
                  :mappingType="saveType"
                  label="저장"
                  icon="save"
                  @beforeAction="saveData"
                  @btnCallback="saveCallback" />
              </q-btn-group>
            </template>
            <template slot="card-detail">
              <div class="col-6">
                <c-text
                  :required="true"
                  :editable="editable && dataeditable"
                  label="기능위치명"
                  name="funcLocationName"
                  type="edit"
                  v-model="data.funcLocationName">
                </c-text>
              </div>
              <div class="col-6">
                <c-plant type="edit" :editable="editable && dataeditable" :required="true" name="plantCd" v-model="data.plantCd" />
              </div>
              <div class="col-6">
                <c-text
                  :editable="false"
                  label="기능위치코드"
                  name="funcLocationCd"
                  type="edit"
                  v-model="data.funcLocationCd">
                </c-text>
              </div>
              <div class="col-6">
                <c-func-location 
                  type="edit" 
                  :plantCd="data.plantCd" 
                  :editable="editable && dataeditable" 
                  label="상위기능위치" 
                  name="upFuncLocationCd" 
                  v-model="data.upFuncLocationCd" />
              </div>
              <div class="col-6">
                <c-text
                  :required="true"
                  :editable="editable && dataeditable"
                  label="순번"
                  name="sortOrder"
                  type="edit"
                  v-model="data.sortOrder">
                </c-text>
              </div>
              <div class="col-6">
                <c-checkbox
                  :editable="editable && dataeditable"
                  :isFlag="true"
                  label="사용여부"
                  name="useFlag"
                  v-model="data.useFlag"
                />
              </div>
            </template>
          </c-card>
        </q-form>
      </div>
    </div>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'location-manage',
  data() {
    return {
      grid: {
        columns: [
          {
            name: 'funcLocationName',
            field: 'funcLocationName',
            label: '기능위치명',
            align: 'left',
            sortable: false,
          },
          {
            name: 'plantName',
            field: 'plantName',
            label: '사업장',
            align: 'center',
            style: 'width:120px',
            sortable: false,
          },
          {
            name: 'sortOrder',
            field: 'sortOrder',
            label: '순번',
            align: 'center',
            style: 'width:70px',
            sortable: false,
          },
          {
            name: 'useFlag',
            field: 'useFlag',
            label: '사용여부',
            align: 'center',
            style: 'width:70px',
            sortable: false,
          },
        ],
        data: [],
      },
      searchParam: {
        plantCd: null,
        useFlag: 'Y',
      },
      data: {
        plantCd: null,  // 사업장코드
        funcLocationCd: '',  // 기능위치 코드
        funcLocationName: '',  // 기능위치 명
        upFuncLocationCd: '',  // 상위 기능위치 코드
        locationLvl: '',  // 기능위치레벨
        useFlag: 'Y',  // 사용여부
        sortOrder: '',  // 순번
      },
      useFlagItems: [
        { code: 'Y', codeName: '사용' },
        { code: 'N', codeName: '미사용' },
      ],
      listUrl: '',
      detailUrl: '',
      checkUrl: '',
      insertUrl: '',
      updateUrl: '',
      deleteUrl: '',
      saveable: false,
      deleteable: false,
      dataeditable: false,
      editable: true,
      updateMode: false,
      isSave: false,
      saveUrl: transactionConfig.mdm.loc.insert.url,
      saveType: 'POST',
      searchUrl: '',
      selectedfuncLocationCd: '',
      selectedfuncs: {},
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.dataeditable = false;
      this.listUrl = selectConfig.mdm.loc.list.url;
      this.detailUrl = selectConfig.mdm.loc.get.url;
      this.insertUrl = transactionConfig.mdm.loc.insert.url;
      this.updateUrl = transactionConfig.mdm.loc.update.url;
      this.deleteUrl = transactionConfig.mdm.loc.delete.url;
      this.getList();
    },
    rowRemoveSelect() {
      var _table = document.getElementsByClassName('bg-light-blue-1')[0];
      if (_table) {
        _table.classList.remove('bg-light-blue-1');
      }
    },
    getLocList() {
      this.getList();
      this.reset();
    },
    getList() {
      this.rowNotSelected = true;
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
      this.saveable = false;
      this.deleteable = false;
    },
    rowClick(row) {
      // 상세조회
      this.saveable = true;
      this.deleteable = true;
      this.rowNotSelected = false;
      this.updateMode = true;
      this.selectedfuncLocationCd = row.funcLocationCd;
      this.selectedfuncs = row;
      this.$http.url = this.$format(this.detailUrl, this.selectedfuncLocationCd);
      this.$http.param = {
        funcLocationCd: this.selectedfuncLocationCd
      }
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.data = _result.data;
        this.saveUrl = this.updateUrl;
        this.saveType = 'PUT';
        this.dataeditable = true;
      },
      () => {
      });
    },
    addData() {
      this.saveable = true;
      this.deleteable = false;
      this.saveUrl = this.insertUrl;
      this.saveType = 'POST';
      this.updateMode = false;
      this.dataeditable = true;
      this.data = {
        plantCd: this.searchParam.plantCd,  // 사업장코드
        funcLocationCd: '',  // 기능위치 코드
        funcLocationName: '',  // 기능위치 명
        upFuncLocationCd: this.selectedfuncs.funcLocationCd,  // 상위 기능위치 코드
        locationLvl: '',  // 기능위치레벨
        useFlag: 'Y',  // 사용여부
        sortOrder: '',  // 순번
      };
    },
    reset() {
      this.rowRemoveSelect();
      this.saveable = false;
      this.deleteable = false;
      this.updateMode = false;
      this.dataeditable = false;
      this.selectedfuncLocationCd = '';
      this.selectedfuncs = {};
      this.data = {
        plantCd: null,  // 사업장코드
        funcLocationCd: '',  // 기능위치 코드
        funcLocationName: '',  // 기능위치 명
        upFuncLocationCd: '',  // 상위 기능위치 코드
        locationLvl: '',  // 기능위치레벨
        useFlag: 'Y',  // 사용여부
        sortOrder: '',  // 순번
      };
    },
    saveData() {
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '저장하시겠습니까?',
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.isSave = !this.isSave;
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    deleteData() {
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '삭제하시겠습니까?',
        // TODO : 필요시 추가하세요.
        type: 'warning', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl, this.data.funcLocationCd);
          this.$http.type = 'DELETE';
          this.$http.request(() => {
            this.isSave = !this.isSave;
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    saveCallback(result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      if (this.saveType == 'PUT') {
        this.getList();
        this.rowClick({ funcLocationCd: result.data.funcLocationCd })
      } else {
        this.getLocList();
      }
    },
    deleteCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getLocList();
    },
  }
};
</script>
